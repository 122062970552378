/* eslint-disable import/no-cycle */
import { Box, Flex, Group, Divider as MantineDivider, Stack, Sx } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useRef } from 'react'

import { AvailableTimeViewModel } from '~/client/onsched/consumer/data-contracts'
import { ReviewSystemSeller } from '~/components/reviews/ReviewCard'
import { PackageType } from '~/components/seller/service-setup/PackageFields'
import StickySideLayout from '~/components/shared/layouts/StickySideLayout'
import {
  ProfileSummaryType,
  QuestionAndAnswer,
  SellerService,
  ServiceType,
} from '~/endpoints/model'
import { ReviewSummaryType } from '~/endpoints/model/review'
import { useScreenSize } from '~/hooks'
import { MAX_SCREEN_WIDTH } from '~/utils/constants'
import { getLocalTimezone } from '~/utils/time'

import { Typography } from '../../shared/text/Typography'
import AboutSection from './AboutSection'
import ComparePackages from './ComparePackages'
import HowItWorksSection from './HowItWorksSection'
import PackagesTabsSection from './PackagesTabsSection'
import ProfileCardSection from './ProfileCardSection'
import QuestionsSection from './QuestionsSection'
import SellerInfoSection from './SellerInfoSection'
import OrderServiceDrawer from './order-service-drawer/OrderServiceDrawer'

export const Title = ({
  value,
  italic,
  italicSx,
}: {
  value?: string
  italic?: string
  italicSx?: Sx
}) => (
  <Group spacing={6}>
    {value && (
      <Typography
        variant="titleSerif"
        sx={{ fontSize: '26px !important', whiteSpace: 'nowrap' }}
      >
        {value}
      </Typography>
    )}
    {italic && (
      <Typography
        variant="titleSerifItalic"
        sx={{ fontSize: '26px !important', whiteSpace: 'nowrap', ...italicSx }}
      >
        {italic}
      </Typography>
    )}
  </Group>
)

interface ServiceDetailsProps {
  serviceType: ServiceType
  user: ProfileSummaryType
  reviews: ReviewSummaryType
}

export type StepName = 'Schedule' | 'Preferences' | 'Confirm Order' | 'Pay'

export type ServicesForm = {
  drawerOpen: boolean
  selectedPackage: PackageType
  days: number
  questions: QuestionAndAnswer[]
  scheduledTime?: AvailableTimeViewModel
  timezone: string
  scheduled: boolean
  step: StepName
  key: string
}

const ServiceDetails = ({ serviceType, user, reviews }: ServiceDetailsProps) => {
  const stickySectionRef = useRef<HTMLDivElement>(null)

  const { isMobileScreen } = useScreenSize()
  const service = user[serviceType]
  const comparePackagesRef = useRef<HTMLDivElement>(null)
  const scheduled = service?.type === ServiceType.consultation

  const Divider = useCallback(
    () => <MantineDivider my={isMobileScreen ? 48 : 56} />,
    [isMobileScreen]
  )

  const formKey = `service-${serviceType}-${user.uid}`

  let selectedPackage: PackageType = 'premium'
  if (service?.base?.price) {
    selectedPackage = 'base'
  } else if (service?.standard?.price) {
    selectedPackage = 'standard'
  } else if (service?.premium?.price) {
    selectedPackage = 'premium'
  }

  const form = useForm<ServicesForm>({
    initialValues: {
      key: formKey,
      drawerOpen: false,
      step: scheduled ? 'Schedule' : 'Preferences',
      selectedPackage,
      days: 1,
      scheduled,
      timezone: getLocalTimezone() ?? '',
      questions: service?.questions?.map(q => ({ ...q, answer: '' })) || [],
    },
    validate: values => {
      return {
        questions:
          values.step !== 'Preferences' ||
          values.questions.every(q => !q.required || q.answer.length)
            ? null
            : 'required',
        scheduledTime:
          values.step !== 'Schedule' ||
          (values.scheduledTime?.date && !isNaN(values.scheduledTime?.time as number))
            ? null
            : 'required',
      }
    },
  })

  const formRef = useRef(form)

  useEffect(() => {
    console.debug('Checking for existing form data: [key=%s]', formKey)
    const value = localStorage.getItem(formKey)
    if (value) {
      localStorage.removeItem(formKey)
      console.debug('Found existing form data, updating form state: %s', value)
      const values = JSON.parse(value)
      formRef.current?.setValues(values)
      formRef.current?.resetDirty(values)
    }
  }, [formKey])

  const scrollToPackages = () => {
    if (comparePackagesRef.current) {
      const yAxis = comparePackagesRef.current?.offsetTop
      window.scrollTo({
        top: yAxis - 150,
        behavior: 'smooth',
      })
    }
  }

  const onCloseOrderServiceDrawerOpened = () => {
    form.setFieldValue('drawerOpen', false)
  }
  console.log(reviews)
  // const onSelectTablePackage = (type = form.values.selectedPackage) => {
  //   form.setValues({ drawerOpen: true, selectedPackage: type })
  // }
  const onSelectTablePackage = (type = form.values.selectedPackage) => {
    form.setValues({ drawerOpen: true, selectedPackage: type })
  }

  return (
    <Flex
      p={isMobileScreen ? '40px 16px' : '48px 88px'}
      maw={MAX_SCREEN_WIDTH}
      gap={isMobileScreen ? undefined : 56}
      mx="auto"
      justify="center"
    >
      <StickySideLayout
        hideDivider
        hideStickyMobile
        stickyContentWrapperRef={stickySectionRef}
        stickyContent={
          <Box ref={stickySectionRef}>
            <PackagesTabsSection
              daysAmount={form.values.days}
              onChangeDaysAmount={(amount: number) => form.setFieldValue('days', amount)}
              packageType={form.values.selectedPackage}
              setSelectedPackage={type => form.setFieldValue('selectedPackage', type)}
              service={service as SellerService}
              scrollToPackages={scrollToPackages}
              onContinue={onSelectTablePackage}
            />
          </Box>
        }
        scrollableContent={
          <Stack
            spacing={0}
            sx={{ maxWidth: 596, width: '100%' }}
          >
            <Flex
              direction="column"
              gap={0}
            >
              {service && (
                <ProfileCardSection
                  proStatus={user.proStatus}
                  serviceType={service.type}
                  name={user.name}
                  homeLocation={user.home?.name}
                  photoUrl={user.sellerPhoto as string}
                  reviews={reviews.totalReviews}
                  rating={reviews.averageRating}
                />
              )}
            </Flex>

            <Divider />
            {isMobileScreen && (
              <>
                <PackagesTabsSection
                  daysAmount={form.values.days}
                  onChangeDaysAmount={(amount: number) => form.setFieldValue('days', amount)}
                  packageType={form.values.selectedPackage}
                  setSelectedPackage={type => form.setFieldValue('selectedPackage', type)}
                  service={service as SellerService}
                  scrollToPackages={scrollToPackages}
                  onContinue={onSelectTablePackage}
                />{' '}
                <Divider />
              </>
            )}

            <AboutSection
              name={user.name}
              description={service?.description || ''}
              serviceType={serviceType}
            />
            {reviews.totalReviews != null && (reviews.totalReviews ?? 0) > 0 && (
              <>
                <Divider />
                <ReviewSystemSeller
                  reviews={reviews}
                  sellerUsername={user.username}
                />
              </>
            )}
            <Divider />

            <HowItWorksSection serviceType={serviceType} />

            <Divider />
            <SellerInfoSection user={user} />

            {/* <Divider />
          <ReviewsSection
            sellerName={user.firstName}
            reviews={[
              {
                name: 'Erica',
                date: 'July 2023',
                description: `“Unbeatable Service! Catalin planned every detail perfectly, making my vacation hassle-free and magical”`,
                rating: 5,
              },
              {
                name: 'John',
                date: 'July 2023',
                description: `The quality of the information, wonderful pictures and practical nature of her comments is exactly what I what I needed.`,
                rating: 5,
              },
              {
                name: 'Michelle',
                date: 'July 2023',
                description: `The quality of the information, wonderful pictures and practical nature of her comments is exactly what I what I needed.`,
                rating: 5,
              },
            ]}
          /> */}

            <Divider />
            <Box ref={comparePackagesRef}>
              <ComparePackages
                onSelect={onSelectTablePackage}
                daysAmount={form.values.days}
                serviceType={serviceType}
                packages={[
                  ...(service?.base ? [{ type: 'base' as PackageType, ...service.base }] : []),
                  ...(service?.standard
                    ? [{ type: 'standard' as PackageType, ...service.standard }]
                    : []),
                  ...(service?.premium
                    ? [{ type: 'premium' as PackageType, ...service.premium }]
                    : []),
                ]}
              />
            </Box>

            <Divider />
            <QuestionsSection serviceType={service?.type || ServiceType.consultation} />
          </Stack>
        }
      />

      {service && (
        <OrderServiceDrawer
          form={form}
          user={user}
          service={service}
          daysAmount={form.values.days}
          packageType={form.values.selectedPackage}
          opened={form.values.drawerOpen}
          onClose={onCloseOrderServiceDrawerOpened}
        />
      )}
    </Flex>
  )
}

export default ServiceDetails
